
import HomePage from '../pages/home.vue';
import WoodlogPage from '../pages/woodlogs.vue';
import ListsPage from '../pages/lists.vue';
import KommFormPage from '../pages/kommform.vue';
import KommPage from '../pages/komm.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/woodlogs/',
    component: WoodlogPage,
  },
  {
    path: '/lists/',
    component: ListsPage,
  },
  {
    path: '/kommform/:id?/',
    component: KommFormPage,
  },
  {
    path: '/komm/:id/',
    component: KommPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
