<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>Stammholz</f7-nav-title>  
    </f7-navbar>
    <f7-list media-list>
      <f7-list-item
        v-for="k in kommissionen"
        :key="k.id"
        :title="k.display_name +' - '+ k.partner_id[1]"
        :subtitle="k.order_date"
        :after="k.total_fixed_meter.toFixed(2)"
        :text="k.note"
        :link="`/komm/${k.id}/`"

        swipeout
        @swipeout:deleted="onDeleted(k.id)"
      >
          <f7-swipeout-actions left>
            <f7-swipeout-button delete confirm-text="Möchtest du wirklich die Kommission und alle Stämme löschen?" color="red">
                <f7-icon ios="f7:bin_xmark_fill" aurora="f7:bin_xmark_fill" md="material:delete_outline"></f7-icon>
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-swipeout-actions right>
            <f7-swipeout-button  @click="editKomm(k.id)">
                <f7-icon ios="f7:pencil" aurora="f7:pencil" md="material:create"></f7-icon>
            </f7-swipeout-button>
          </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>

    <f7-fab
      position="center-bottom"
      slot="fixed"
      text="Kommission erstellen"
      href="/kommform/"
    >
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState( {
      kommissionen: state => state.kommissionen,
    }),
  },
  methods: {
    ...mapActions([
      'delKommission',
      'loadKommissionen'
    ]),
    async onDeleted (kommid) {
      const app = this.$f7
      //await this.$dbnew.delPolterbyId(polterid)
      this.delKommission(kommid)
      app.dialog.alert('Kommission erfolgreich gelöscht')
    },
    editKomm (kommid) {
      console.log('passing id', kommid)
      this.$f7.views.current.router.navigate('/kommform/' + kommid+'/')
    },
    loaddata() {
      this.loadKommissionen
    }
  },
}
</script>