<template>
  <f7-page v-on:page:tabshow="loaddata()">
    <f7-navbar title="Holzlisten">
       <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".vl2"
          search-item="li"
          search-in=".item-title"
          placeholder="Suche"
        ></f7-searchbar>
       </f7-subnavbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>
    <f7-list
      class="searchbar-found vl2"
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          media-item
          :title="item.wood_list_id[1] + '-' + item.number"
          :subtitle="item.liste.owner[1]"
          :after="item.trunk_expression_fixed_meter.toFixed(2)"
          :style="`top: ${vlData.topPosition}px`"
          @click="showlist(item.wood_list_id[1] + '-' + item.number)"
        >
         <f7-chip outlined slot="media" :text="item.trunk_expression_sold_count" color="darkgreen"></f7-chip>
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
  export default {
    data() {
      return {
        vlData: {
          items: [],
        },
      };
    },
    computed:{
      ...mapState( {
        staemme: state => state.staemme,
      }),
      ...mapGetters({
        items: 'formatedSorts'
      })
    },
    watch: {
      items: function(newi, oldi) {
        var vl = this.$f7.virtualList.get('.vl2');
        vl.replaceAllItems(newi)
      }
    },
    methods: {
      ...mapActions([
        "loadStaemme",
        "loadSorts"
      ]),
      searchAll(query, items) {
        const found = []
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].wood_list_id[1].toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
          if (items[i].liste.owner[1].toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
      showlist(list) {
        var tab = this.$f7.views.woodlogs.el
        var sbar = this.$f7.searchbar.get('.sbar')
        sbar.search(list)
        this.$f7.tab.show(tab)
      },
      loaddata() {
        this.loadStaemme()
        this.loadSorts()
      }
    },
  };
</script>