<template>
  <f7-page name="home" v-on:page:tabshow="loaddata()" hide-toolbar-on-scroll>
    <f7-navbar title="Kommission" back-link="Back"></f7-navbar>

    <kommOv :komm="komm" />
    <!-- -------------------------- Input ------------------ -->
    <f7-block style="padding-top: 0; padding-bottom: 0; margin-top: 2; margin-bottom: 2; margin: 5px">
        <f7-row>
            <f7-col width="90">
                <f7-input
                    id="autocomplete-dropdown-stamm"
                    label="Stammnr"
                    type="number"
                    outline
                    floating-label
                    placeholder="Stammnummer"
                    :value="suche"
                    @input="suche = $event.target.value"
                    style="font-size: 20px; margin: 5px; height: 30px"
                    ref="inputref"
                    clear-button
                    @focus="handleFocus()"
                ></f7-input>
            </f7-col>
            <f7-col width="10">
                <f7-button @click="scanner = !scanner; showkeyboard()" class="button">
                    <f7-icon v-if="!scanner" ios="f7:keyboard" aurora="f7:keyboard" md="material:keyboard"></f7-icon>
                    <f7-icon v-if="scanner" ios="f7:barcode" aurora="f7:barcode" md="material:scanner"></f7-icon>
                </f7-button>
            </f7-col>
        </f7-row>
         Anzahl Gefundene Stämme: {{ result && result.length ? result.length : 0 }}
    </f7-block>
    <!--<kommInput :suche.sync="suche" :result="result"/>
     -------------------------- Stämme ------------------ -->
    <!--<f7-block-title>Stämme in der Kommission</f7-block-title>-->
        <f7-block style="margin: 5px">
            <f7-row no-gap>
                <f7-col>Stamm</f7-col>
                <f7-col>BA</f7-col>
                <f7-col>Kl</f7-col>
                <f7-col>Länge</f7-col>
                <f7-col>DM</f7-col>
                <f7-col>Stärke</f7-col>
                <f7-col>FM</f7-col>
            </f7-row>
            <span v-if="komm.order_lines">
                    <f7-row no-gap 
                        v-for="e in komm.order_lines" :key="e.id"
                        v-if="Object.keys(e.wood_trunk_id).length > 0"
                        style="border-bottom: 1px solid lightgrey"
                        :class="[{ 'highlight' : e.id === activeentry}, { 'zebra' : e.id !== activeentry}]"
                        @click='editStamm(e)'
                        >
                            <f7-col>{{e.product_id[1]}}</f7-col>
                            <f7-col>{{e.wood_type_id[1]}}</f7-col>
                            <f7-col>{{e.wood_quality_id[1]}}</f7-col>
                            <f7-col style="text-align:center;padding-right: 3px">{{e.wood_trunk_id.length}}</f7-col>
                            <f7-col style="text-align:center;padding-right: 3px">{{e.wood_trunk_id.diameter_center}}</f7-col>
                            <f7-col style="text-align:center;padding-right: 3px">{{e.wood_trunk_id.wood_strengh_class_id_calc[0]}}</f7-col>
                            <f7-col style="text-align:right;padding-right: 3px">{{e.wood_trunk_id.fixed_meter_calc.toFixed(2)}}</f7-col>
                    </f7-row>
            </span>
        </f7-block>



        <!-- -------------------------- Modal Form ------------------ -->

        <f7-sheet class="sheet" style="height: 70vh" :opened="sheetOpened" @sheet:closed="sheetOpened = false; activeentry = null; edit = false; searchinput.focus();">
            <f7-toolbar>
                    <f7-button @click="edit = !edit" class="button" v-if='activeentry > 0' >
                        <f7-icon ios="f7:pencil_outline" aurora="f7:pencil_outline" md="material:note_add"></f7-icon>
                    </f7-button>
                    <f7-button @click="delStamm(activeentry)" class="button" v-if='activeentry > 0' >
                        <f7-icon ios="f7:bin_xmark_fill" aurora="f7:bin_xmark_fill" md="material:delete_outline"></f7-icon>
                    </f7-button>
                <div class="right">
                    <f7-link sheet-close>Schliessen</f7-link>
                </div>
            </f7-toolbar>
            <f7-page-content>
                <f7-block v-if="activeentry && !edit">
                    <f7-row>
                        <f7-col width="25">Stammnr</f7-col><f7-col width="25">{{activeentry_data.product_id[1]}}</f7-col>
                        <f7-col width="25">Holzart</f7-col><f7-col width="25">{{activeentry_data.wood_type_id[1]}}</f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="25">Klasse</f7-col><f7-col width="25">{{activeentry_data.wood_quality_id[1]}}</f7-col>
                        <f7-col width="25">Stärke</f7-col><f7-col width="25">{{activeentry_data.wood_trunk_id.wood_strengh_class_id_calc[1]}}</f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="25">Durchmesser</f7-col><f7-col width="25">{{activeentry_data.wood_trunk_id.diameter_center}}</f7-col>
                        <f7-col width="25">Länge</f7-col><f7-col width="25">{{activeentry_data.wood_trunk_id.length}}</f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="25">Rindenabzug</f7-col><f7-col width="25">{{activeentry_data.wood_trunk_id.bark_deduction}} cm</f7-col>
                        <f7-col width="25">fm</f7-col><f7-col width="25">{{activeentry_data.wood_trunk_id.fixed_meter_calc.toFixed(2)}}</f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="25">Holzliste</f7-col><f7-col width="75">{{activeentry_data.wood_trunk_id.wood_list_id[1]}}</f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="25">Beleg</f7-col><f7-col width="75">{{activeentry_data.wood_trunk_id.wood_list_id[0]}}/{{activeentry_data.product_id[0]}}</f7-col>
                    </f7-row>
                </f7-block>
                <f7-block v-if="edit">
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                            ref="stammnr"
                            label="Stammnr"
                            type="text"
                            placeholder="Stammnr"
                            :value="activeentry_data.wood_trunk_id.product_id[1]"
                            @input="activeentry_data.wood_trunk_id.product_id[1] = $event.target.value"
                            clear-button
                        ></f7-list-input>
                        <f7-list-input
                            label="Holzart"
                            type="select"
                            placeholder="Holzart"
                            :value="activeentry_data.wood_trunk_id.wood_type_id[0]"
                            @input="activeentry_data.wood_trunk_id.wood_type_id = $event.target.value"
                        >
                            <option v-for="q in holzarten" :key="q.id" :value="q.id">{{q.description}}</option>
                        </f7-list-input>
                        <f7-list-input
                            ref="laenge"
                            label="Länge"
                            type="text"
                            placeholder="Länge"
                            :value="activeentry_data.wood_trunk_id.length"
                            @input="activeentry_data.wood_trunk_id.length = $event.target.value"
                            clear-button
                        ></f7-list-input>
                         <f7-list-input
                            ref="dm"
                            label="Durchmesser"
                            type="text"
                            placeholder="Durchmesser"
                            :value="activeentry_data.wood_trunk_id.diameter_center"
                            @input="activeentry_data.wood_trunk_id.diameter_center = $event.target.value"
                            clear-button
                        ></f7-list-input>
                        <f7-list-input
                            label="Qualität"
                            type="select"
                            placeholder="Qualität"
                            :value="activeentry_data.wood_trunk_id.wood_quality_id[0]"
                            @input="activeentry_data.wood_trunk_id.wood_quality_id = $event.target.value"
                        >
                            <option v-for="q in quali" :key="q.id" :value="q.id">{{q.name}}</option>
                        </f7-list-input>
                    
                        <f7-list-item title="Stärkeklasse (ber)" >{{ comp_staerke }}</f7-list-item>
                        <f7-list-item title="Rindenabzug (ber) " >{{ activeentry_data.wood_trunk_id.bark_deduction }}</f7-list-item>
                        <f7-list-item title="Festmeter " >{{ activeentry_data.wood_trunk_id.fixed_meter_calc }}</f7-list-item>
                        <f7-button @click="saveEditStamm(activeentry_data)" fill round>
                            Speichern
                        </f7-button>
                    </f7-list>                  
                </f7-block>
            </f7-page-content>
        </f7-sheet>
  </f7-page>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import kommOv from '@/components/kommOv.vue'
import kommInput from '@/components/kommInput.vue'

import rabzugjson from '@/assets/rabzug.json'
import stammdatenjson from '@/assets/db.json'


export default {
    rabzuege: rabzugjson,
    
    data() {
        return {
            suche: null,
            result: [],
            kommid: this.$f7route.params.id,
            activeentry: null,
            activeentry_data: [],
            sheetOpened: false,
            edit: false,
            stammdaten: stammdatenjson,
            searchinput: null,
            scanner: true,
        }
    },

    components :{
        kommOv,
        kommInput
    },
    computed:{
        komm () {
            return this.$store.getters.getKommById(this.kommid)
        },
        ...mapState( {
            holzarten: state => state.holzarten,
            quali: state => state.quali,
            stkl: state => state.stkl,
            rabzug: state => state.rabzug
        }),
        comp_staerke: function () {
            var staerke = null
            if (this.activeentry_data.wood_trunk_id.diameter_center != null && this.activeentry_data.wood_trunk_id.bark_deduction != null) {
                var durchmesser = parseFloat(this.activeentry_data.wood_trunk_id.diameter_center)
                var rabzug = parseFloat(this.activeentry_data.wood_trunk_id.bark_deduction)
                var dm = durchmesser - rabzug
                var staerke = this.stkl.filter(s => s.diameter_from <= dm && dm <= s.diameter_to)
                if (staerke.length > 0) {
                    staerke = staerke[0]
                }
            } else {
                staerke = null
            }
            this.activeentry_data.wood_trunk_id.wood_strengh_class_id_calc = [staerke.id, staerke.name]
            this.activeentry_data.wood_trunk_id.wood_strengh_class = staerke.name
            return staerke['name']
        }
    },
    watch: {
        result: function (neu, alt) {
            if (neu.length === 1) {
                this.addStamm()
            }
        },
        suche: function (neu, alt) {
            if (neu) {
                if (neu.substr(0,1)==0){
                    this.suche = neu.substr(1,20)
                }
                if (neu.length >=3) {
                    this.result = this.$store.getters['getStammByNr'](neu)
                } else {
                    this.result = ['']
                }
            }
        },
        activeentry_data: {
            handler(val, alt){
                if (!Array.isArray(alt)) {
                    if (val) {
                        // Klassesb
                        if (val.wood_trunk_id.wood_quality_id) {
                            var qual = val.wood_trunk_id.wood_quality_id
                            if (!Array.isArray(qual)) {
                                var qual_bez = this.quali.find(q => q.id === parseInt(qual))
                                val.wood_trunk_id.wood_quality_id = [parseInt(qual), qual_bez.name]
                            }
                        }

                        // Holzartsb
                        if (val.wood_trunk_id.wood_type_id) {
                            var ha = val.wood_trunk_id.wood_type_id
                            if (!Array.isArray(ha)) {
                                var ha_bez = this.holzarten.find(h => h.id === parseInt(ha))
                                val.wood_trunk_id.wood_type_id = [parseInt(ha), ha_bez.name]
                            }
                        }


                        // Rindenabzug
                        var rabzug = 0

                        if (Array.isArray(val.wood_trunk_id.wood_type_id) & val.wood_trunk_id.diameter_center != null) {
                            var ha = val.wood_trunk_id.wood_type_id
                            var dm = val.wood_trunk_id.diameter_center
                            var f_abzug = this.rabzug.find(r => r.wood_type_id[0] === ha[0] && r.diameter_center === parseInt(dm))
                            if (typeof(f_abzug) !== 'undefined') {
                                val.wood_trunk_id.bark_deduction = f_abzug.deduction_cm
                            } else {
                                val.wood_trunk_id.bark_deduction = 0
                            }
                        }

                        //FM
                        var fm = 0;
                        if (val.wood_trunk_id.diameter_center != null && val.wood_trunk_id.bark_deduction != null && val.wood_trunk_id.length != null) {
                            var durchmesser = parseFloat(val.wood_trunk_id.diameter_center)
                            var rabzug = parseFloat(val.wood_trunk_id.bark_deduction)
                            var laenge = parseFloat(val.wood_trunk_id.length)

                            fm = this.round(3.1416 / 4 * Math.pow(((durchmesser - rabzug) / 100), 2) * laenge, 3)
                            val.wood_trunk_id.fixed_meter = fm
                            val.wood_trunk_id.fixed_meter_calc = fm
                        } else {
                            val.wood_trunk_id.fixed_meter = fm
                            val.wood_trunk_id.fixed_meter_calc = fm
                        }
                    }
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$f7ready(async (f7) => {
            this.loaddata();
            this.searchinput = document.getElementById('autocomplete-dropdown-stamm').firstChild;
            //this.searchinput.focus();
            var that = this;
            var autcomplete = this.$f7.autocomplete.create({
                inputEl: '#autocomplete-dropdown-stamm input',
                openIn: 'dropdown',
                valueProperty: 'name',
                textProperty: 'display_name',
                limit: 20,
                dropdownPlaceholderText: 'Stammnummer....',
                source: function (query, render) {
                    render(that.result);
                },
                on: {
                    change: function (value) {
                        that.result = value
                    },
                },
            });


        })
    },
    methods: {
        ...mapActions([
                        'editKommission', 
                        'loadKommissionen', 
                        'loadStaemme',
                        'addLogtoKommission',
                        'removeLogfromKommission',
                        'loadHolzarten',
                        'loadQuali',
                        'loadStkl',
                        'loadRabzug',
                        'editLoginKommission'
                    ]),
        addStamm() {
            var exists = this.komm.order_lines.filter(k => k.product_id[0] === this.result[0].product_id[0])
            if (exists.length === 0) {
                //TODO: ADD if offline
                this.addLogtoKommission({expr: this.result[0], kommid: this.kommid})
                this.suche = null
                this.result = []
            } else {
                this.$f7.notification.create({
                    title: 'Fehler',
                    text: 'Stamm bereits in der Liste vorhanden',
                    closeOnClick: true
                }).open();
                console.log('komm', this.komm)
            }
        },
        editStamm(e) {
            if (this.activeentry === parseInt(e.id) && this.activeentry > 0) {
                this.activeentry = null
                this.activeentry_data = null
                this.sheetOpened = false
            } else {
                this.activeentry = parseInt(e.id)
                this.sheetOpened = true
                this.activeentry_data = {...e}
            }
        },
        saveEditStamm(e) {
            console.log('mut data', e)
            this.sheetOpened = false;
            this.editLoginKommission({expr: e, kommid: this.kommid})
            /*this.komm.entries.splice(this.komm.entries.findIndex(a => a.belegberposid === e.belegberposid),1)
            this.activeentry = null
            this.komm.entries.unshift(e)
            this.activeentry_data = null
            this.edit = false
            this.editKommission(this.komm)
            */
        },
        delStamm(e) {          
            //TODO: REMOVE if OFFLINE
            this.removeLogfromKommission(e)
            this.activeentry = null
            this.activeentry_data = null
            this.sheetOpened = false
        },
        round (x, n) {
        var a = Math.pow(10, n)
        return (Math.round(x * a) / a)
        },
        loaddata () {
            this.loadKommissionen();
            this.loadStaemme();
            this.loadHolzarten();
            this.loadQuali();
            this.loadStkl();
            this.loadRabzug();
        },
        handleFocus() {
            //this.searchinput.blur();
            if (this.scanner) {
                this.searchinput.readOnly = true;
                this.searchinput.focus();
                setTimeout(function(){document.getElementById('autocomplete-dropdown-stamm').firstChild.readOnly = false;}, 50);
            } else {
                this.searchinput.focus();
            }
        },
        showkeyboard() {
            this.searchinput.blur();
            if (!this.scanner) {
                this.searchinput.focus();
            } else {
                this.searchinput.readOnly = true;
                this.searchinput.focus();
                setTimeout(function(){document.getElementById('autocomplete-dropdown-stamm').firstChild.readOnly = false;}, 50);
            }
        }
    },
    onPageBeforeOut() {
      this.$f7.sheet.close();
    },

    onPageBeforeRemove() {
      if (this.sheet) this.sheet.destroy();
    },
}
</script>