<template>
  <f7-app :params="f7params" >

  <!-- Left panel with cover effect-->
  <f7-panel left cover>
    <f7-view>
      <f7-page>
        <f7-navbar title="Stammholz"></f7-navbar>
        <center><img src="static/icons/128x128.png" /></center>
        <f7-block-title>Menu</f7-block-title>
        <f7-list>
          <f7-list-item panel-close title="Daten laden" @click='loaddata()'></f7-list-item>
          <f7-list-item panel-close title="Update" @click='update()'></f7-list-item>
          <f7-list-item panel-close title="Logout" @click='logout()'></f7-list-item>
        </f7-list>
        <f7-block-footer>
          Raurica Holzvermarktung AG
        </f7-block-footer>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <f7-toolbar tabbar labels bottom :style="[!online ? {'background-color': 'red'}:{}]">
      <f7-link tab-link="#view-home" tab-link-active icon-ios="f7:cart" icon-aurora="f7:cart" icon-md="f7:cart" text="Kommissionen"></f7-link>
      <f7-link tab-link="#view-woodlogs" icon-ios="f7:tree" icon-aurora="f7:tree" icon-md="f7:tree" text="Stämme"></f7-link>
      <f7-link tab-link="#view-lists" icon-ios="f7:list" icon-aurora="f7:list" icon-md="material:list_number" text="Listen"></f7-link>
    </f7-toolbar>

    <f7-view id="view-home" main tab tab-active url="/"></f7-view>
    <f7-view id="view-woodlogs" ref="tabwoodlogs" name="woodlogs" tab url="/woodlogs/"></f7-view>
    <f7-view id="view-lists" ref="tablists" name="lists" tab url="/lists/"></f7-view>

  </f7-views>


    <!-- Popup -->
    <f7-popup id="my-popup">
      <f7-view>
        <f7-page>
          <f7-navbar title="Popup">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>Popup content goes here.</p>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    <f7-login-screen id="my-login-screen" :opened="loginScreenOpened" @loginscreen:closed="loginScreenOpened = false">
      <f7-view>
        <f7-page login-screen>
          <center><img src="static/icons/128x128.png" /></center>
          <f7-login-screen-title>Raurica Stammholz</f7-login-screen-title>
          <center><small>Testumgebung</small></center>
          <f7-list form>
            <f7-list-input
              type="text"
              name="username"
              placeholder="Benutzername"
              :value="username"
              @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              type="password"
              name="password"
              placeholder="Passwort"
              :value="password"
              @input="password = $event.target.value"
            ></f7-list-input>
          </f7-list>
          <f7-list>
            <f7-list-button title="Login" @click="alertLoginData"></f7-list-button>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

  export default {
    data() {
      return {
        f7params: {
          name: 'Stammholz',
          theme: 'auto',
          // App root data
          data: function () {
            return {
            };
          },
          routes: routes,
          serviceWorker: {
            path: '/service-worker.js',
          },
        },
        loginScreenOpened: true,
        username: '',
        password: '',
      }
    },
    computed: 
      mapState({
        online: state => state.isonline,
        token: state => state.token,
        loggin: state => state.loggin
      },
    ),
    methods: {
       ...mapActions([
        "login",
        "logout",
        "check_login",
        "loadKommissionen",
        "loadStaemme",
        "loadSorts",
        "loadListen"
      ]),
      alertLoginData() {
        this.login({username: this.username, password: this.password})
      },
      update() {
        caches.keys().then(function(cacheNames) {
            return Promise.all(
              cacheNames.filter(function(cacheName) {
              }).map(function(cacheName) {
                return caches.delete(cacheName);
              })
            );
          })
        window.location.reload();
      },
      loaddata() {
          this.loadKommissionen()
          this.loadStaemme()
          this.loadSorts()
          this.loadListen()
      }
    },
    watch: {
      loggin: function(newVal) {
        if (newVal == false) {
          this.loginScreenOpened = true;
          this.$f7.loginScreen.open();
        } else {
          this.$f7.loginScreen.close();
          this.loginScreenOpened = false;
          this.loaddata();
        }
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        if (!this.loggin) {
          this.$f7.loginScreen.open();
        } else {
          this.loginScreenOpened = false;
        }
      });
    }
  }
</script>