<template>
  <f7-page v-on:page:tabshow="loaddata()" style=" padding-bottom:4rem">
    <f7-navbar title="Stämme">
       <f7-subnavbar :inner="false">
        <f7-searchbar
          class="sbar"
          search-container=".virtual-list"
          search-item="li"
          search-in=".item-title"
          placeholder="Suche"
        ></f7-searchbar>
       </f7-subnavbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found"></f7-list-item>
    </f7-list>
    <f7-list
      class="searchbar-found vl"
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: $theme.ios ? 63 : ($theme.md ? 73 : 46)}"
      ref="vl"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          media-item
          @click="sheetOpened = true; activelog = item"
          :title="item.product_id[1]"
          :subtitle="item.wood_list_id[1] + '-' + item.wood_collection_id[1]"
          :text="show_purchased_sold(item)"
          :after="item.fixed_meter_calc.toFixed(2)"
          :style="`top: ${vlData.topPosition}px;`"
        >
         <f7-chip outlined slot="media" :text="item.wood_type_id[1]" :color="colorschema[item.wood_type_id[1]]"></f7-chip>
        </f7-list-item>
      </ul>
    </f7-list>
  
    <f7-sheet class="demo-sheet" :opened="sheetOpened" @sheet:closed="sheetOpened = false">
      <f7-toolbar>
        <div class="left"></div>
        <div class="right">
          <f7-link sheet-close>Schliessen</f7-link>
        </div>
      </f7-toolbar>
      <f7-page-content>
        <f7-block>
          <f7-row>
            <f7-col width="25">Stammnr</f7-col><f7-col width="25">{{activelog.product_id[1]}}</f7-col>
            <f7-col width="25">Holzart</f7-col><f7-col width="25">{{activelog.wood_type_id[1]}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Klasse</f7-col><f7-col width="25">{{activelog.wood_quality_id[1]}}</f7-col>
            <f7-col width="25">Stärke</f7-col><f7-col width="25">{{activelog.wood_strengh_class_id_calc[1]}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Durchmesser</f7-col><f7-col width="25">{{activelog.diameter_center}}</f7-col>
            <f7-col width="25">Länge</f7-col><f7-col width="25">{{activelog.length}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Rindenabzug</f7-col><f7-col width="25">{{activelog.bark_deduction}} cm</f7-col>
            <f7-col width="25">fm</f7-col><f7-col width="25">{{activelog.fixed_meter_calc.toFixed(3)}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Holzliste</f7-col><f7-col width="75">{{activelog.wood_list_id[1]}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Stammkomm.</f7-col><f7-col width="75">{{activelog.note}}</f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="25">Beleg</f7-col><f7-col width="75">{{activelog.wood_list_id[0]}}/{{activelog.product_id[0]}}</f7-col>
          </f7-row>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
  export default {
    data() { 
      return {
        colorschema:{'BU':'green','BA': 'red', 'ES': 'yellow', 'EI': 'blue', 'LI': 'pink'},
        sheetOpened: false,
        vlData: {
          items: [],
        },
        activelog: {
          product_id:[], 
          wood_type_id:[], 
          wood_quality_id:[], 
          wood_strengh_class_id_calc: [], 
          wood_list_id:[],
          owner:[],
          fixed_meter_calc: 0
          },
      };
    },
    computed: {
      ...mapState( {
        items: state => state.staemme,
        listen: state => state.listen
      }),
      activelist: function () {
        if (this.activelog.wood_list_id[0]) {
          return this.listen.find(list => list.id = this.activelog.wood_list_id[0])
        } else {
          return {'note': '', owner: []}
        }
      },
    },
    watch: {
      items: function(newi, oldi) {
        var vl = this.$f7.virtualList.get('.vl');
        vl.replaceAllItems(newi)
      }
    },
    methods: {
      ...mapActions([
        "loadStaemme",
        "loadListen"
      ]),
      ...mapMutations([
        "CLEAR_STAEMME"
      ]),
      searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].product_id[1].toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
          if ( (items[i].wood_list_id[1] + '-' + items[i].wood_collection_id[1]).toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
        }
        return found;
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
      show_purchased_sold (item) {
        return (item.draft_purchase ? '🛒':'') + 
               (item.is_purchased ? '✔️':'') + '/' +
               (item.draft_sale ? '💰':'') + 
               (item.is_sold ? '✔️':'');
      },
      loaddata() {
        this.loadStaemme()
        this.loadListen()
      }
    },
    onPageBeforeOut() {
      this.$f7.sheet.close();
    },

    onPageBeforeRemove() {
      if (this.sheet) this.sheet.destroy();
    },
  };
</script>