<template>
    <f7-block strong style="padding-top: 0; padding-bottom: 0;  margin-top: 2px; margin-bottom: 2px; margin: 5px">
        <f7-row>
            <f7-col width="20"><b>Kunde:</b></f7-col>
            <f7-col width="80">{{komm.partner_id[1]}}</f7-col>
        </f7-row>
        <f7-row>
            <f7-col width="20"><b>Datum:</b></f7-col>
            <f7-col width="80">{{komm.date_order}}</f7-col>
        </f7-row>
        <f7-row>
            <f7-col width="20"><b>Notiz:</b></f7-col>
            <f7-col width="80">{{komm.note}}</f7-col>
        </f7-row>
    </f7-block>
</template>
<script>
export default {
    props: {
        komm: Object
    }
}
</script>