<template>
  <f7-page name="home">
    <f7-navbar v-bind:title="edit? 'Kommission aktualisieren': 'Kommission erstellen'" back-link="Back"></f7-navbar>
    <f7-block-title>Kommission</f7-block-title>
    <f7-list no-hairlines-md>
         <f7-list-input
            id="autocomplete-dropdown-ajax"
            label="Kunde"
            type="text"
            placeholder="Kunde"
            :value="komm.komm"
            @input="komm.komm = $event.target.value"
            required
            validate
        ></f7-list-input>
        <f7-list-input
            label="Datum"
            type="date"
            placeholder="Datum"
            :value="komm.date"
            @input="komm.date = $event.target.value"
            validate
        ></f7-list-input>
        <f7-list-input
            label="Note"
            type="text"
            placeholder="Note"
            :value="komm.note"
            @input="komm.note = $event.target.value"
            validate
        ></f7-list-input>
    </f7-list>
     <f7-block>
        <f7-row>
            <f7-col width="20"/>
            <f7-col width="60"><f7-button class="col" round fill @click="saveKom">{{ edit ? 'Aktualisieren': 'Erstellen' }}</f7-button></f7-col>
            <f7-col width="20"/>
        </f7-row>
     </f7-block>
  </f7-page>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data() {
        return {
            komm: {
                id: this.$uuid.v4(),
                komm: null,
                partner_id: null,
                date: null,
                note: null,
            },
            kommid: this.$f7route.params.id,
            edit: false
        }
    },
    computed:{
        ...mapState( {
            partner: state => state.partner,
        })
    },
    mounted() {
        this.$f7ready(async (f7) => {
            this.loadPartner()

            if (this.kommid != null) {
                this.edit = true
                var t = this.$store.getters['getKommById'](this.kommid)
                this.komm = {
                                id: t.id,
                                komm: t.partner_id[1],
                                partner_id: t.partner_id[0],
                                date: t.date_order.substring(0,10),
                                note: t.note,
                            }
            }

            var that = this;
            var autocompleteDropdownAjax = this.$f7.autocomplete.create({
                inputEl: '#autocomplete-dropdown-ajax input',
                openIn: 'dropdown',
                preloader: true,
                valueProperty: 'display_name',
                textProperty: 'display_name',
                limit: 20,
                dropdownPlaceholderText: 'Kunde',
                source: function (query, render) {
                    var results = [];
                    if (query.length === 0) {
                        render(results);
                        return;
                    }
                    for (var i = 0; i < that.partner.length; i++) {
                        if (that.partner[i].display_name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(that.partner[i])
                    }
                    render(results);
                },
                on: {
                    change: function (value) {
                        that.komm.partner_id = value[0].id
                    },
                },
            });
        })
    },
    methods: {
        ...mapActions(["addKommission", 'editKommission', 'loadPartner']),
        async saveKom () {
            if (this.edit === false) {
                this.addKommission(this.komm)
                //this.$f7.views.current.router.navigate('/komm/' + this.komm.id + '/')
                this.$f7.views.current.router.navigate('/')
            } else {
                this.editKommission(this.komm)
                this.$f7.views.current.router.navigate('/')
            }
        }
    }
}
</script>