import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import qs from 'qs'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
console.log('process', process)
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

var that = this;


api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    console.log('not logged in... please logout')
    localStorage.clear();
  }
  return error;
});

export default new Vuex.Store(
    {
        state: {
          isonline: window.navigator.onLine,
          loggin: false,
          token: null,
          serviceworker_message: {},

          // Holz
          staemme: [],
          listen: [],
          sorts: [],

          // Order
          kommissionen: [{partner_id:[], total_fixed_meter: 0}],
          komm_lines: [],

          // Stammdaten
          holzarten: [],
          quali: [],
          stkl: [],
          rabzug: [],
          partner: []
        },
        plugins: [createPersistedState()],
        getters: {
          staemmeByHolzliste(state) {
            const groupByHolzliste = (array, key) => {
              return array.reduce((result, currentValue) => {
                (result[currentValue[key]] = result[currentValue[key]] || {})
                result[currentValue[key]].staemme = result[currentValue[key]].staemme || []
                result[currentValue[key]].fm = (result[currentValue[key]].fm || 0) + currentValue['m3']
                result[currentValue[key]].anz = (result[currentValue[key]].anz || 0) + 1
                result[currentValue[key]].lief = currentValue['radressesb']
                result[currentValue[key]].bes = currentValue['besitzercode']
                result[currentValue[key]].liste = currentValue['holzliste']
                // Holzarten zusammenzug
                result[currentValue[key]].ba = result[currentValue[key]].ba || []
                result[currentValue[key]].ba[currentValue['holzartsb']] = (result[currentValue[key]].ba[currentValue['holzartsb']] || 0) + currentValue['m3']
      
                // Qualitäten
                result[currentValue[key]].klasse = result[currentValue[key]].klasse || []
                result[currentValue[key]].klasse[currentValue['klassesb']] = (result[currentValue[key]].klasse[currentValue['klassesb']] || 0) + currentValue['m3']
      
                result[currentValue[key]].staemme.push(
                  currentValue
                );
                return result;
              },{});
            };
      
            return Object.values(groupByHolzliste(state.staemme, 'holzliste'));
          },
          formatedSorts(state) {
            return state.sorts.filter(sorts=> sorts.trunk_expression_sold_state !== 'finished')
                              .map(sorts => ({...sorts, 'liste': {...state.listen.find(liste => liste.id === sorts.wood_list_id[0])}}))
          },
          getKommById: (state) => (id) => {
            const komm = state.kommissionen.filter(k => k.id == (id.toString()))
            return komm.map(k => (
                                    {...k, 
                                      'order_lines': [
                                        ...state.komm_lines.filter(ol => ol.order_id[0] === k.id)
                                        .map(ol => (
                                          {...ol,
                                          'wood_trunk_id':{
                                            ...state.staemme.find(stamm => stamm.id === ol.wood_trunk_expression_id[0])
                                          }}
                                        ))
                                      ]
                                    }
                                )
                            )[0]
          },
          getStammByNr: (state) => (stammnr) => {
            return state.staemme.filter(k => k.product_id[1].substr(0,stammnr.length) === stammnr && k.draft_sale === false)
          }
        },
        mutations: {
          set_status(state, onlinestatus) {
            state.isonline = onlinestatus
          },
          set_serviceworker(state, message) {
            state.serviceworker_message = message
          },
          //----- FLECTRA
          set_login(state, login) {
            state.loggin = login
          },
          set_token(state, token) {
            state.token = token
          },

          //----- STAMMDATEN
          SET_HOLZARTEN(state, holzarten) {
            state.holzarten= holzarten
          },
          SET_QUALI(state, quali) {
            state.quali= quali
          },
          SET_STKL(state, stkl) {
            state.stkl = stkl
          },
          SET_RABZUG(state, rabzug) {
            state.rabzug = rabzug
          },
          SET_PARTNER(state, partner) {
            state.partner = partner
          },


          //----- STAEMME
          SET_STAEMME(state, staemme) {
            state.staemme = staemme
          },
          CLEAR_STAEMME(state) {
            state.staemme = [{stammnr: 111}]
          },

          //----- LISTEN
          SET_LISTEN(state, listen) {
            state.listen = listen
          },

          //----- SORTIMENTE
          SET_SORTS(state, sorts) {
            state.sorts = sorts
          },

          //------ Kommissionen
          SET_KOM(state, kommissionen) {
            state.kommissionen = kommissionen
          },
          SET_KOM_LINES(state, komm_lines) {
            state.komm_lines = komm_lines
          },
          ADD_KOM(state, kommission) {
            state.kommissionen.unshift(kommission)
          },
          DEL_KOM(state, id) {
            state.kommissionen.splice(state.kommissionen.findIndex(e => e.id === id),1)
          },
          UP_KOM(state, kommission) {
            var orig = state.kommissionen
            Object.assign(orig.find(element => element.id === kommission.id), kommission);
            console.log('update', orig)
            state.kommissionen = orig
          }
        },
        actions: {
// -------------- LOGIN
          check_login({commit}) {
            if (this.token === '' || this.token === null) {
              commit('set_login', false)
            } else {
              // TODO CHECK TOKEN STATE
              commit('set_login', true)
            }
          },
          login({ commit }, data) {
            api.post(
              'api/auth/get_tokens',
              "username="+data.username+"&password="+data.password+"&db="+process.env.VUE_APP_DATABASE,
              {headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              }},
                    )
            .then(r => {
              commit('set_token', r.data.access_token)
              commit('set_login', true)
            })
            .catch(e => {
                alert(e)
            })
          },
          logout({ commit }) {
              commit('set_token', null),
              commit('set_login', false)
          },

// -------------- STAEMME
          loadStaemme ({ commit, state }) {
            api
              .get('api/wm.wood.trunk.expression',
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'access_token': state.token,
                      },
                      params: {
                        //field: "['name', 'wood_type_id', 'wood_quality_id', 'fixed_meter_calc', 'is_sold', 'is_purchased', 'active', 'wood_list_id', 'wood_collection_id']",
                        filters: "[('active','=',True)]"
                      }
                    }
                  )
              .then(staemme => {
                commit('SET_STAEMME', staemme.data.results)
              })
          },

// -------------- LISTEN
          loadListen ({ commit, state }) {
            api
              .get('api/wm.wood.list',
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'access_token': state.token,
                      },
                      params: {
                        //filters: "[('active','=',True)]"
                      }
                    }
                  )
              .then(listen => {
                commit('SET_LISTEN', listen.data.results)
              })
          },
// -------------- SORTIMENTE
          loadSorts ({ commit, state }) {
            api
              .get('api/wm.wood.collection',
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'access_token': state.token,
                      },
                      params: {
                        //filters: "[('active','=',True)]"
                      }
                    }
                  )
              .then(sorts => {
                commit('SET_SORTS', sorts.data.results)
              })
          },

// -------------- STAMMDATEN
          async loadHolzarten ({ commit, state }) {
            const ha = await api.get('api/wm.wood.type',{headers: {'access_token': state.token}})
            commit('SET_HOLZARTEN', ha.data.results)
          },

          async loadQuali ({ commit, state }) {
            const q = await api.get('api/wm.wood.quality', {headers: {'access_token': state.token}})
            commit('SET_QUALI', q.data.results)
          },

          async loadStkl ({ commit, state }) {
            const s = await api.get('api/wm.strength.class',{headers: {'access_token': state.token}})
            commit('SET_STKL', s.data.results)
          },
          async loadRabzug ({ commit, state }) {
            const r = await api.get('api/wm.bark.deduction',{headers: {'access_token': state.token}})
            commit('SET_RABZUG', r.data.results)
          },
          async loadPartner ({ commit, state }) {
            const r = await api.get('api/res.partner',{headers: {'access_token': state.token}, params:{filters:"[('customer', '=', True)]", field:"['id','display_name']"}})
            commit('SET_PARTNER', r.data.results)
          },

// -------------- KOMMISSIONEN
          async loadKommissionen ({ commit, state }) {

            const kommissionen = await api
              .get(process.env.VUE_APP_API_URL+'/api/sale.order?' + new URLSearchParams({
                        filters: "[('state','=','draft')]",
                        order: "name desc"
                      }),
                    {
                      headers: {
                        'access_token': state.token,
                        'Content-Type': 'application/x-www-form-urlencoded'
                      },
                    }
                  )
            commit('SET_KOM', kommissionen.data.results)

            //TODO: nicht nach orderlines filtern sondern nach order_id
            const sale_orders = [...new Set(state.kommissionen.map(item => item.id))];
            const order_lines = await api
              .get('api/sale.order.line',
                      {
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                          'access_token': state.token,
                        },
                        params: { // " + sale_orders.join(",") + "
                          filters: "[('order_id','in',[" + sale_orders.join(",") + "])]",
                        }
                      }
                    )
            commit('SET_KOM_LINES', order_lines.data.results)

                  
          },
          async addLogtoKommission({ commit, state }, {expr, kommid}) {
            // TODO: Manage Offline

            var result1 = await api({
              method: 'put',
              url: 'api/wm.wood.trunk.expression/'+expr.id,
              data:  qs.stringify({'draft_sale': true}),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })

            await api({
              method: 'post',
              url: 'api/sale.order.line',
              data:  qs.stringify({
                    'name': expr.product_id[1],
                    'order_id': kommid,
                    'product_id': expr.product_id[0],
                    'product_uom_qty': expr.fixed_meter_calc,
                    //'product_uom': expression.product_id.uom_po_id.id,
                    'wood_trunk_expression_id': expr.id,
                    'wood_type_id': expr.wood_type_id[0],
                    'wood_quality_id': expr.wood_quality_id[0],
                    'wood_strengh_class_id': expr.wood_strengh_class_id_calc[0]
                  }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })

            this.dispatch('loadKommissionen')
          },


          async editLoginKommission({ commit, state }, {expr, kommid }) {
            // remove order line
            await this.dispatch('removeLogfromKommission', expr.id)
            
            // mark expression as inactive
            var result1 = await api({
              method: 'put',
              url: 'api/wm.wood.trunk.expression/'+expr.wood_trunk_id.id,
              data:  qs.stringify({'active': null,
                                   'note': 'App Mutation'}),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })

            // add new expression
            var result = await api({
              method: 'post',
              url: 'api/wm.wood.trunk.expression',
              data:  qs.stringify({'active': true,
                                    'bark_deduction': expr.wood_trunk_id.bark_deduction,
                                    'company_id': expr.wood_trunk_id.company_id[0],
                                    'diameter_center': expr.wood_trunk_id.diameter_center,
                                    'fixed_meter': expr.wood_trunk_id.fixed_meter,
                                    'fixed_meter_calc': expr.wood_trunk_id.fixed_meter_calc,
                                    'length': expr.wood_trunk_id.length,
                                    'note': expr.wood_trunk_id.note,
                                    'note_wood_list': expr.wood_trunk_id.note_wood_list,
                                    'polter_id': expr.wood_trunk_id.polter_id,
                                    'product_id': expr.wood_trunk_id.product_id[0],
                                    'qty': expr.wood_trunk_id.qty,
                                    'uom_id': expr.wood_trunk_id.uom_id[0],
                                    'wood_collection_id': expr.wood_trunk_id.wood_collection_id[0],
                                    'wood_list_id': expr.wood_trunk_id.wood_list_id[0],
                                    'wood_product_tmpl_id': expr.wood_trunk_id.wood_product_tmpl_id[0],
                                    'wood_quality_id': expr.wood_trunk_id.wood_quality_id[0],
                                    'wood_strengh_class': expr.wood_trunk_id.wood_strengh_class,
                                    'wood_strengh_class_id_calc': expr.wood_trunk_id.wood_strengh_class_id_calc[0],
                                    'wood_type_id': expr.wood_trunk_id.wood_type_id[0],
                                    'draft_purchase': (expr.wood_trunk_id.draft_purchase===true?true:null),
                                    'is_purchased': (expr.wood_trunk_id.is_purchased===true?true:null),
                                    'draft_sale': true
                                  }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })

            var expr_neu = expr.wood_trunk_id
            expr_neu.id = result.data.id

            this.dispatch('addLogtoKommission', {expr: expr_neu, kommid: kommid})
            this.dispatch('loadStaemme')
            this.dispatch('loadKommissionen')
          },
          


          async removeLogfromKommission({ commit, state }, id) {
            // Todo: Manage Offline
            await api.delete('api/sale.order.line/'+id, {headers: {'access_token': state.token}})
            this.dispatch('loadKommissionen')
          },

          async addKommission ({ commit, state }, kommission) {
            //Todo: Manage Offline
            //commit('ADD_KOM', kommission)
            await api({
              method: 'post',
              url: 'api/sale.order',
              data:  qs.stringify({
                    'partner_id': kommission.partner_id,
                    'date_order': kommission.date,
                    'note': kommission.note
                  }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })
            this.dispatch('loadKommissionen')
          },
          async editKommission({ commit, state }, kommission) {
            //Todo: Manage Offline
            //commit('UP_KOM', kommission)

            await api({
              method: 'put',
              url: 'api/sale.order/'+kommission.id,
              data:  qs.stringify({
                    'partner_id': kommission.partner_id,
                    'date_order': kommission.date,
                    'note': kommission.note
                  }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'access_token': state.token,
              }
            })
            this.dispatch('loadKommissionen')
          },
          delKommission ({ commit, state }, id) {
            commit('DEL_KOM', id)
            api.delete('kommission/'+id)
            .then(ret => {console.log(ret)})
            .catch(error => {console.log(error)})
          }
        }
    }
)