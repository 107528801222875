<template>
    <f7-block>
        <f7-input
            label="Stammnr"
            type="text"
            outline
            floating-label
            placeholder="Stammnummer"
            :value="suche"
            @input="suche = $event.target.value"
            style="font-size: 20px; margin: 5px; height: 30px"
            clear-button
        ></f7-input>
         Anzahl Gefundene Stämme: {{ result && result.length ? result.length : 0 }}
    </f7-block>
</template>
<script>
export default {
    props: {
        result: Array,
        suche: String
    }
}
</script>