import Vue from 'vue';
import '../registerServiceWorker'

import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
//import Framework7 from 'framework7';

//import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import Framework7Vue from 'framework7-vue';
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.less';

import App from '../components/app.vue';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  Vue,
  dsn: "https://095d196b2d3f4ee5b71ad2a4094bd85a@o541819.ingest.sentry.io/5838808",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true, 
  environment: process.env.NODE_ENV
});


import {f7App, f7Navbar, f7Panel, f7View, 
         f7Page, f7BlockTitle, f7List, f7ListItem, 
         f7BlockFooter, f7Views, f7Toolbar, f7Link, 
         f7Popup, f7NavRight, f7NavLeft, f7NavTitle, f7Subnavbar, f7LoginScreen, 
         f7LoginScreenTitle, f7ListInput, f7ListButton, f7Block,
         f7Input, f7Chip, f7Button,
         f7Col, f7Row, f7PageContent, f7Searchbar, f7Fab, f7Icon, f7Sheet, f7SwipeoutActions, f7SwipeoutButton} from 'framework7-vue';


Vue.component('f7-app', f7App);
Vue.component('f7-navbar', f7Navbar);
Vue.component('f7-panel', f7Panel);
Vue.component('f7-view', f7View);
Vue.component('f7-views', f7Views);
Vue.component('f7-page', f7Page)
Vue.component('f7-block-title', f7BlockTitle)
Vue.component('f7-block', f7Block)
Vue.component('f7-list', f7List)
Vue.component('f7-list-item', f7ListItem)
Vue.component('f7-list-input', f7ListInput)
Vue.component('f7-list-button', f7ListButton)
Vue.component('f7-block-footer', f7BlockFooter)
Vue.component('f7-input', f7Input)
Vue.component('f7-button', f7Button)
Vue.component('f7-toolbar', f7Toolbar)
Vue.component('f7-link', f7Link)
Vue.component('f7-popup', f7Popup)
Vue.component('f7-nav-right', f7NavRight)
Vue.component('f7-nav-left', f7NavLeft)
Vue.component('f7-nav-title', f7NavTitle)
Vue.component('f7-subnavbar', f7Subnavbar)
Vue.component('f7-login-screen', f7LoginScreen)
Vue.component('f7-login-screen-title', f7LoginScreenTitle)
Vue.component('f7-chip', f7Chip)
Vue.component('f7-col', f7Col)
Vue.component('f7-row', f7Row)
Vue.component('f7-page-content', f7PageContent)
Vue.component('f7-searchbar', f7Searchbar)
Vue.component('f7-fab', f7Fab)
Vue.component('f7-icon', f7Icon)
Vue.component('f7-sheet', f7Sheet)
Vue.component('f7-swipeout-actions', f7SwipeoutActions)
Vue.component('f7-swipeout-button', f7SwipeoutButton)


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);


import Vuex from 'vuex';
import UUID from "vue-uuid";
Vue.use(Vuex)
Vue.use(UUID)
import store from './store'

// Init App
new Vue({
  store,
  el: '#app',
  render: (h) => h(App),
  components: {
    app: App
  },
  methods: {
    updateStatus: function() {
      if (typeof window.navigator.onLine === 'undefined') {
        this.isOnline = true
        store.commit('set_status', true)
      } else {
        this.isOnline = window.navigator.onLine
        store.commit('set_status', window.navigator.onLine)
      }
    },
  },
  async mounted () {
    console.log('this', this)
    var that = this
    navigator.serviceWorker.ready
      .then(async function (reg) {
        reg.addEventListener('updatefound', () => {
          console.log('Update found. Waiting for install to complete.')
          const installingWorker = reg.installing
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed') {
              console.log('Install complete. Triggering update prompt.')
              that.$f7.dialog.confirm('Eine neue Version ist verfügbar. Soll diese aktualisiert werden?', function () {
                window.location.reload()
              })
            }
          })
        })
      })

    
    this.updateStatus()
    window.addEventListener('online', this.updateStatus)
    window.addEventListener('offline', this.updateStatus)
    navigator.serviceWorker.addEventListener('message', event => {
      console.warn('EVENT', '---------------')
      console.log(event.data.msg, event.data.url);
      store.commit('set_serviceworker', event.data)
      store.dispatch('loadStaemme')
      store.dispatch('loadKommissionen')
    });
    
    }
});